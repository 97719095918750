<template>
	<v-card max-width="500" class="mx-auto mt-5" elevation="0" :color="color" :dark="dark" rounded="xl">
		<div class="d-flex justify-center mb-2" v-if="$route.name == 'AuthResetPassword'">
			<v-btn plain fab active-class="" :to="{ name: 'Landing' }">
				<v-avatar size="96">
					<v-img src="@/assets/images/icon/logo-oversos-second-2x.png" />
				</v-avatar>
			</v-btn>
		</div>
		<v-card-title>
			<h1 class="display-1 mx-auto">
				{{ $t('auth.resetPassword') }}
			</h1>
		</v-card-title>
		<v-card-text class="pb-0">
			<v-form ref="form" v-model="valid">
				<v-text-field
					v-model="password"
					:type="showPassword ? 'text' : 'password'"
					:label="$t('auth.password')"
					:hint="$t('auth.passwordHelp')"
					:rules="rules.password"
					required
					outlined
					rounded
					prepend-inner-icon="mdi-lock"
					hide-details="auto"
					class="pb-3"
					:append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
					@click:append="showPassword = !showPassword"
				/>
				<v-text-field
					v-model="passwordAgain"
					:type="showPassword ? 'text' : 'password'"
					:label="$t('auth.passwordAgain')"
					:rules="rules.passwordAgain"
					required
					outlined
					rounded
					prepend-inner-icon="mdi-lock"
					hide-details="auto"
					class="pb-3"
					:append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
					@click:append="showPassword = !showPassword"
				/>
			</v-form>
		</v-card-text>

		<v-card-actions class="d-flex align-center justify-center flex-wrap pt-0">
			<v-btn class="px-4" color="primary" large rounded :loading="loading" @click="callResetPassword()">
				{{ $t('settings.changePassword') }}
			</v-btn>
		</v-card-actions>
	</v-card>
</template>

<script>
import { mapActions } from 'vuex'

export default {
	name: 'ResetPasswordForm',
	props: {
		color: {
			type: String,
			default: '',
		},
		dark: {
			type: Boolean,
			required: true,
		},
		token: {
			type: String,
			required: true,
		},
	},
	data() {
		return {
			loading: false,
			valid: false,
			password: '',
			passwordAgain: '',
			showPassword: false,
			rules: {
				password: [
					(v) => !!v || this.$t('rules.required'),
					(v) => (v && v.length <= this.passwordMaxLength) || this.$t('rules.length', { length: this.passwordMaxLength }),
				],
				passwordAgain: [(v) => v === this.password || this.$t('rules.passwordAgainMatch')],
			},
		}
	},
	computed: {
		passwordMaxLength() {
			return 128
		},
	},
	methods: {
		callResetPassword() {
			this.$refs.form.validate()
			if (!this.valid) return
			this.loading = true
			this.resetPassword({ token: this.token, password: this.password, passwordAgain: this.passwordAgain })
				.then(({ success }) => {
					if (success) {
						this.$router.push({ name: 'Landing' })
					}
				})
				.then(() => {
					this.loading = false
				})
		},
		...mapActions('auth', ['resetPassword']),
	},
}
</script>
